import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const About: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div
      className=" text-center flex justify-center items-center"
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
    >
      <div className="container">
        <div className="welcome-content text-center flex">
          {/* <div className="w-full pt-3  flex items-center justify-center">
            <div className="border-b-2 hidden md:block border-b-black w-[100px] dark:border-b-white "></div>
          </div> */}
          {/* <div
            className="flex items-center text-primary cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <FaChevronLeft />
            <span className="pl-2 pb-[1px]">Back</span>{" "}
          </div> */}
          <div className="w-full">
            <h1 className="font-medium text-3xl  text-gray-900 dark:text-white p-3 ">
              Programs&nbsp;&&nbsp;Events
            </h1>
          </div>
          <div className="p-1"></div>
          {/* <div className="w-full pt-3  flex items-center justify-center">
            <div className="border-b-2 hidden md:block border-b-black w-[100px] dark:border-b-white "></div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default About;
