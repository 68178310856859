import React, { useState } from "react";
import "./card.css";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";

interface ProgProps {
  data: any;
}

const ProgramCards: React.FC<ProgProps> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleNav = (id: string) => {
    navigate(`/careers/${id}`);
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="w-full max-w-full bg-white rounded-xl shadow-lg mb-2"
      data-aos="slide-right"
    >
      {/* Accordion Header */}
      <div
        className="flex justify-between items-center p-3 cursor-pointer"
        onClick={toggleAccordion}
      >
        <div className="flex flex-col w-full">
          <div className="flex items-center justify-between w-full pr-0 md:pr-5">
            <div className="text-xl capitalize font-medium ">{data?.name}</div>
            <div className="font-medium text-primary text-[14px]">
              <span className="text-gray-300">Last Date: </span>
              {data?.last_date &&
                moment(data?.last_date).format("DD-MM-YY, hh:mm")}
            </div>

            <div className="relative flex items-center justify-end ">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleNav(data?.id);
                }}
                className="relative flex h-6 items-center justify-center px-3 text-sm font-semibold text-white bg-primary rounded-full hover:scale-105 transition-all"
              >
                Apply
              </button>
            </div>
          </div>
          <div className="flex items-center mt-3">
            <div className="mb-1 text-primary text-[14px]">Tags&nbsp;: </div>
            <div className="flex py-3 mx-3 h-6 w-fit items-center justify-center px-2 text-gray-700 text-[10px] border border-gray-700 rounded-3xl shadow-lg capitalize">
              career
            </div>
          </div>
        </div>

        <div className="ml-3  text-lg">
          {isOpen ? <FaChevronDown /> : <FaChevronRight />}
        </div>
      </div>

      {/* Accordion Content */}
      {isOpen && (
        <div className="p-3">
          <div className=" venturi text-start text-[13px] ml-3">
            {data?.description
              ? parse(data?.description)
              : "No data to display"}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgramCards;
