

import {jwtDecode, JwtPayload} from "jwt-decode";
import Cookies from "js-cookie";

import { LoginResponse,RegisterResponse,AuthResponse, UserDataType } from "./types";
import axiosInstance from "../axios";
import { useAuthStore } from "./store";


export const login = async (email: string, password: string): Promise<LoginResponse> => {
    try {
      const { data, status } = await axiosInstance.post<AuthResponse>("auth/login/", {
        email,
        password,
      });
      if (status === 200) {
        setAuthUser(data.access, data.refresh);
      }
      return { data, error: null };
    } catch (error: any) {
      return {
        data: null,
        error: error?.response?.data?.detail || "Something went wrong",
      };
    }
  };
  
  export const register = async (
    fullName: string,
    email: string,
    phone: string,
    password: string,
    password2: string
  ): Promise<RegisterResponse> => {
    try {
      const { data } = await axiosInstance.post("auth/register/", {
        full_name: fullName,
        email,
        phone,
        password,
        password2,
      });
      await login(email, password);
      return { data, error: null };
    } catch (error: any) {
      return {
        data: null,
        error: error?.response?.data?.detail || "Something went wrong",
      };
    }
  };
  
  export const logout = (): void => {
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    useAuthStore.getState().setUser(null);
  };
  
  export const setUser = async (): Promise<void> => {
    const refreshToken = Cookies.get("refresh_token");
    const accessToken = Cookies.get("access_token");
  
    if (!accessToken || !refreshToken) {
      return;
    }
  
    if (isAccessTokenExpired(accessToken)) {
      const { access, refresh } = await getRefreshToken();
      setAuthUser(access, refresh);
    } else {
      setAuthUser(accessToken, refreshToken);
    }
  };
  
  export const setAuthUser = (accessToken: string, refreshToken: string): void => {
    Cookies.set("access_token", accessToken, { expires: 1, secure: true });
    Cookies.set("refresh_token", refreshToken, { expires: 7, secure: true });
  
    const user = jwtDecode<JwtPayload & UserDataType>(accessToken) || null;
    if (user) {
      useAuthStore.getState().setUser(user);
    }
    useAuthStore.getState().setLoading(false);
  };
  
  export const getRefreshToken = async (): Promise<AuthResponse> => {
    const refreshToken = Cookies.get("refresh_token");
  
    if (!refreshToken) {
      throw new Error("No refresh token available");
    }
  
    const { data } = await axiosInstance.post<AuthResponse>("auth/refresh/", {
      refresh: refreshToken,
    });
  
    return data;
  };
  
  export const isAccessTokenExpired = (accessToken: string): boolean => {
    try {
      const decodedToken: { exp: number } = jwtDecode(accessToken);
      return decodedToken.exp < Date.now() / 1000;
    } catch (error) {
      console.error("Error decoding token", error);
      return true;
    }
  };