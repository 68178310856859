import React, { useState } from "react";
import SearchIcon from "../../../../../assets/svg/search.svg"

interface FilterProps {
  handleSearch: (value: string) => void;
}

const Filter: React.FC<FilterProps> = ({ handleSearch }) => {
  const [query, setQuery] = useState("");
  console.log(query);
  return (
    <div
      className={` z-50 group p-2 sm:p-2 rounded-xl bg-white border border-gray-100 dark:border-gray-700 dark:bg-gray-800 bg-opacity-50  shadow-gray-600/10 ${
        query !== "" ? "shadow-glow" : "hover:shadow-glow "
      } `}
    >
      <div className="flex">
        <input
          type="text"
          placeholder="Search..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className=" rounded-md px-4 py-1 dark:border-none border border-1 focus:outline-none dark:focus:ring-0 "
          style={{
            fontSize: "16px",
            width: "330px",
            // border : "0"

          }}
        />
        <button
          onClick={() => {
            handleSearch(query);
          }}
          className="bg-primary text-white px-2  rounded-md ml-2 cursor-pointer block"
        >
          <img src={SearchIcon} className="w-5 text-white"/>
        </button>
      </div>
    </div>
  );
};

export default Filter;
