import React, { useEffect, useState } from "react";
import { SeoContext, SeoData, SeoContextType } from "./SeoContext";

// SEOProvider component to manage SEO data and update the document head
export const SeoProvider = ({ children }: { children: React.ReactNode }) => {
  const [seoData, setSeoData] = useState<SeoData>({
    title: "Strategic Sourcing", // Default title
    description: "Default description", // Default description
    keywords: "react, seo, default", // Default keywords
  });

  // Effect to update the document head (title, description, keywords)
  useEffect(() => {
    document.title = seoData.title;

    // Update the description meta tag
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.setAttribute("name", "description");
      document.head.appendChild(metaDescription);
    }
    metaDescription.setAttribute("content", seoData.description);

    // Update the keywords meta tag
    let metaKeywords = document.querySelector('meta[name="keywords"]');
    if (!metaKeywords) {
      metaKeywords = document.createElement("meta");
      metaKeywords.setAttribute("name", "keywords");
      document.head.appendChild(metaKeywords);
    }
    metaKeywords.setAttribute("content", seoData.keywords);
  }, [seoData]);

  // Function to update SEO data
  const updateSeoData: SeoContextType["updateSeoData"] = (title, description, keywords) => {
    setSeoData({ title, description, keywords });
  };

  return (
    <SeoContext.Provider value={{ updateSeoData }}>
      {children}
    </SeoContext.Provider>
  );
};
