import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Select from "react-select";
import axiosInstance from "../../../../../utils/axios";
import AsyncSelect from "react-select/async";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

interface Country {
  id: string;
  name: string;
}
const today = new Date();
const minAllowedDate = new Date(
  today.getFullYear() - 18,
  today.getMonth(),
  today.getDate()
);

const schema = z
  .object({
    fullName: z.string().min(1, "Full Name is required"),
    dob: z
      .string()
      .min(1, "Date of Birth is required")
      .refine(
        (dateStr) => {
          const selectedDate = new Date(dateStr);
          return selectedDate <= minAllowedDate;
        },
        {
          message: "You must be at least 18 years old",
        }
      ),
    nationality: z.object({
      value: z.string().min(1, "Nationality is required"),
      label: z.string().min(1, "Nationality is required"),
    }),
    currentLocation: z.object({
      value: z.string().min(1, "Current Location is required"),
      label: z.string().min(1, "Current Location is required"),
    }),
    visaStatus: z.object({
      value: z.string().min(1, "Visa Status is required"),
      label: z.string().min(1, "Visa Status is required"),
    }),
    qualOther: z.boolean().optional(),
    otherQualification: z.string().optional(),
    exprtOther: z.boolean().optional(),
    otherExpertise: z.string().optional(),
    exprtArea: z.boolean().optional(),
    otherArea: z.string().optional(),

    drivingLicense: z.object({
      hasLicense: z.object({
        value: z.string(),
        label: z.string(),
      }),
      country: z
        .object({
          label: z.string(),
          value: z.string(),
        })
        .optional(),
      type: z
        .object({
          label: z.string(),
          value: z.string(),
        })
        .optional(),
    }),
    mobileNumber: z
      .string()
      .regex(/^\d{7,15}$/, "Invalid phone number (7-15 digits)"),
    whatsappNumber: z
      .string()
      .regex(/^\d{7,15}$/, "Invalid phone number (7-15 digits)")
      .optional(),
    email: z.string().email("Invalid email address"),
    linkedin: z.string().url("Invalid URL"),
    highestQualification: z.object({
      label: z.string().min(1, "Qualification is required"),
      value: z.string().min(1, "Qualification is required"),
    }),
    fieldOfSpecialization: z.string().min(1, "Specialization is required"),
    experience: z
      .object({
        label: z.string(),
        value: z.string(),
      })
      .optional(),
    certifications: z.string().optional(),
    currentPosition: z.string().optional(),
    currentOrganization: z.string().optional(),
    industryExpertise: z
      .array(
        z.object({
          label: z.string(),
          value: z.string(),
        })
      )
      .optional(),
    areasOfExpertise: z
      .array(
        z.object({
          label: z.string(),
          value: z.string(),
        })
      )
      .optional(),
    resume: z.any().refine((file) => file && file[0], "Resume is required"),
  })
  .refine((data) => {
    if (!data?.qualOther || data?.otherQualification?.trim()?.length) {
      return {
        message: "This field is required .",
        path: ["otherQualification"],
      };
    }
    if (data?.exprtOther || data?.otherExpertise?.trim()?.length) {
      return {
        message: "This field is required .",
        path: ["otherExpertise"],
      };
    }
    if (data?.exprtArea || data?.otherArea?.trim()?.length) {
      return {
        message: "This field is required .",
        path: ["otherArea"],
      };
    }
  });

const Form = () => {
  type SelectOption = { value: string; label: string };
  const { control, register, handleSubmit, setValue, watch, formState } =
    useForm<any>({
      resolver: zodResolver(schema),
    });
  const navigate = useNavigate();
  const params = useParams().id;
  const [countries, setCountries] = useState<Country[]>([]);
  const [licType, setLicType] = useState<Country[]>([]);
  const [visa, setVisa] = useState<Country[]>([]);
  const [qual, setQual] = useState<Country[]>([]);
  const [expert, setExpert] = useState<Country[]>([]);
  const [experience, setExperience] = useState<Country[]>([]);
  const [area, setArea] = useState<Country[]>([]);
  const [watchLicense, setWatchLicense] = useState<boolean>();
  const [watchExpert, setWatchExpert] = useState<boolean>();
  const [watchArea, setWatchArea] = useState<boolean>();
  const [file, setFile] = useState<string>();
  console.log(watch("drivingLicense.hasLicense.value"));
  // const watchLicense =
  //   watch("drivingLicense.hasLicense") == true ? true : false;
  const watchMobileNumber = watch("mobileNumber");

  const onSubmit = (data: any) => {
    console.log({ data });
    const formData = new FormData();
    if (data) {
      if (data?.fullName) {
        formData.append("fullname", data.fullName);
      }
      if (data?.dob) {
        formData.append("date_of_birth", data.dob);
      }
      if (data?.nationality?.value) {
        formData.append("nationality", data.nationality.value);
      }
      if (data?.highestQualification?.value) {
        formData.append(
          "highest_qualification",
          data.highestQualification.value
        );
      }
      if (data?.currentLocation?.value) {
        formData.append("current_location", data.currentLocation.value);
      }
      if (data?.visaStatus?.value) {
        formData.append("visa_status", data.visaStatus.value);
      }
      if (data?.drivingLicense?.hasLicense.value == "true") {
        formData.append(
          "licence_issuing_country",
          data.drivingLicense.country.value
        );
      }
      if (data?.drivingLicense?.hasLicense.value == "true") {
        formData.append("licence_type", data.drivingLicense.type.value);
      }
      if (data?.mobileNumber) {
        formData.append("mobile_number", data.mobileNumber);
      }
      if (data?.whatsappNumber) {
        formData.append("whatsapp_number", data.whatsappNumber);
      }
      if (data?.email) {
        formData.append("email", data.email);
      }
      if (data?.fieldOfSpecialization) {
        formData.append("specialization", data.fieldOfSpecialization);
      }
      if (data?.linkedin) {
        formData.append("linkedin_url", data.linkedin);
      }
      if (data?.otherQualification) {
        formData.append("other_highest_qualification", data.otherQualification);
      }
      if (data?.otherExpertise) {
        formData.append("other_industry_expertise", data.otherExpertise);
      }
      if (data?.otherArea) {
        formData.append("other_area_expertise", data.otherArea);
      }
      if (data?.experience?.value) {
        formData.append("experience", data.experience.value);
      }
      if (data?.certifications) {
        formData.append("professional_qualification", data.certifications);
      }
      if (data?.currentPosition) {
        formData.append("current_position", data.currentPosition);
      }
      if (data?.currentOrganization) {
        formData.append("current_organisation", data.currentOrganization);
      }
      if (data?.resume && data.resume[0]) {
        formData.append("resume", data.resume[0]);
      }
      if (params) {
        formData.append("role_applied", params as string);
      }
      if (data?.industryExpertise) {
        data.industryExpertise.forEach((item) => {
          if (item?.value) {
            formData.append("industry_expertise", item.value);
          }
        });
      }
      if (data?.areasOfExpertise) {
        data.areasOfExpertise.forEach((item) => {
          if (item?.value) {
            formData.append("area_expertise", item.value);
          }
        });
      }
    }

    applicantRegister(formData);
  };
  const applicantRegister = async (data) => {
    try {
      const response = await axiosInstance.post(
        `https://api.stratsourceme.com/api/v1/careers/applicants`,
        data
      );
      Swal.fire({
        title: "Success",
        text: "Application submitted successfully",
        icon: "success",

        confirmButtonColor: "#ff4f00",
        confirmButtonText: "Okay",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        }
      });
    } catch (err: any) {}
  };
  const fetchCountryData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/registers/countries`
      );
      // console.log(response)
      const arr =
        response?.data?.map((item) => ({
          value: item.id, // Use the country name as the value
          label: item.name, // Use the country name as the label
        })) || [];
      // console.log(response)
      setCountries(arr);
    } catch (err: any) {}
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: SelectOption[]) => void
  ) => {
    fetchLocationData(inputValue)
      .then((data) => {
        const options = data.map((item: Country) => ({
          value: item.id, // assuming `id` is the unique identifier
          label: item.name, // the label shown to the user
        }));
        callback(options); // Provide options to callback
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
      });
  };

  const fetchLocationData = async (inputValue: string): Promise<Country[]> => {
    const response = await axiosInstance.get(
      `https://api.stratsourceme.com/api/v1/registers/locations?search=${inputValue}`
    );
    return response.data; // Return data as Country array
  };

  const fetchlicenceData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/registers/licence_types`
      );
      // console.log(response)
      const arr =
        response?.data.map((item) => ({
          value: item.id, // Use the country name as the value
          label: item.name, // Use the country name as the label
        })) || [];
      setLicType(arr);
    } catch (err: any) {}
  };
  const fetchVisaData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/registers/visa_statuses`
      );
      // console.log(response)
      const arr =
        response?.data.map((item) => ({
          value: item.id, // Use the country name as the value
          label: item.name, // Use the country name as the label
        })) || [];
      setVisa(arr);
    } catch (err: any) {}
  };
  const fetchQualData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/registers/qualification_types`
      );
      // console.log(response)
      const arr =
        response?.data.map((item) => ({
          value: item.id, // Use the country name as the value
          label: item.name, // Use the country name as the label
        })) || [];
      setQual(arr);
    } catch (err: any) {}
  };
  const fetchExperienceData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/registers/experiances`
      );
      // console.log(response)
      const arr =
        response?.data.map((item) => ({
          value: item.id, // Use the country name as the value
          label: item.name, // Use the country name as the label
        })) || [];
      setExperience(arr);
    } catch (err: any) {}
  };
  const fetchExpertiseData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/registers/industry_expertises`
      );
      // console.log(response)
      const arr =
        response?.data.map((item) => ({
          value: item.id, // Use the country name as the value
          label: item.name, // Use the country name as the label
        })) || [];
      setExpert(arr);
    } catch (err: any) {}
  };
  const fetchAreaData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/registers/area_expertises`
      );
      // console.log(response)
      const arr =
        response?.data.map((item) => ({
          value: item.id, // Use the country name as the value
          label: item.name, // Use the country name as the label
        })) || [];
      setArea(arr);
    } catch (err: any) {}
  };
  // useEffect(() => {
  //   if (
  //     watch("industryExpertise").some((item: any) => item?.name === "Others")
  //   ) {
  //     setWatchExpert(true);
  //     setValue("exprtOther", true);
  //   } else {
  //     setWatchExpert(false);
  //     setValue("exprtOther", true);
  //   }
  // }, [watch("industryExpertise")]);

  useEffect(() => {
    const industryExpertise = watch("industryExpertise"); // Store watched value in a variable

    if (
      industryExpertise &&
      industryExpertise.some((item: any) => item?.label === "Others")
    ) {
      console.log(1);
      setWatchExpert(true);
      setValue("exprtOther", true);
    } else {
      console.log(2);
      setWatchExpert(false);
      setValue("exprtOther", false); // Fix value to false when condition isn't met
    }
  }, [watch("industryExpertise")]);
  useEffect(() => {
    const areasOfExpertise = watch("areasOfExpertise"); // Store watched value in a variable

    if (
      areasOfExpertise &&
      areasOfExpertise.some((item: any) => item?.label === "Others")
    ) {
      console.log(1);
      setWatchArea(true);
      setValue("exprtArea", true);
    } else {
      console.log(2);
      setWatchArea(false);
      setValue("exprtArea", false); // Fix value to false when condition isn't met
    }
  }, [watch("areasOfExpertise")]);
  useEffect(() => {
    fetchVisaData();
    fetchCountryData();
    fetchlicenceData();
    fetchQualData();
    fetchExperienceData();
    fetchExpertiseData();
    fetchAreaData();
  }, []);
  useEffect(() => {
    setWatchLicense(
      watch().drivingLicense?.hasLicense?.value == "true" ? true : false
    );
    // console.log(watch("drivingLicense.hasLicense.value"));
  }, [watch().drivingLicense?.hasLicense?.value]);
  useEffect(() => {
    setFile(watch("resume[0].name"));
    // console.log(watch("resume[0].name"));
  }, [watch("resume")]);
  // console.log(watch());
  // console.log(formState.errors);
  const getSelectStyles = (theme: any) => {
    return {
      ...theme,
      // borderRadius: 0,
      colors: {
        ...theme.colors,
        primary25: "#ff510085",
        primary: "#ff4f00",
      },
    };
  };

  const licenseError: any = formState?.errors?.drivingLicense;
  return (
    <div className="max-w-lg mx-auto placeholder-gray-200 text-gray-900 dark:text-gray-100">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          // handleSubmit(onSubmit);
        }}
        className="space-y-4"
      >
        <div className=" p-4 border border-gray-200 rounded-3xl my-3">
          <h2 className="text-xl font-bold text-center">
            Applicant Information
          </h2>
          <div className="mt-3">
            <label>Full Name</label>
            <input
              {...register("fullName")}
              placeholder="Full Name"
              className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
              type="text"
            />
            <p className="text-[12px] text-red-600 pt-1">
              {formState.errors.fullName?.message as string}
            </p>
          </div>
          <div className="mt-3">
            <label>Date of Birth</label>
            <input
              {...register("dob")}
              type="date"
              placeholder="dd/mm/yyyy"
              className="w-full placeholder-gray-200 p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
            />
            <p className="text-[12px] text-red-600 pt-1">
              {formState.errors.dob?.message as string}
            </p>
          </div>

          <div className="mt-3">
            <label className="">Nationality</label>
            <Controller
              name="nationality"
              control={control}
              render={({ field }) => (
                <Select
                  theme={getSelectStyles}
                  // styles={{
                  //   control: (baseStyles, state) => ({
                  //     ...baseStyles,
                  //     borderColor: state.isFocused ? 'grey' : 'red',
                  //   }),
                  // }}

                  className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  {...field}
                  options={countries}
                  isClearable
                  isSearchable
                  placeholder="Search and Select"
                />
              )}
            />
            <p className="text-[12px] text-red-600 pt-1">
              {formState.errors.nationality?.message as string}
            </p>
          </div>

          <div className="mt-3">
            <label>Current Location (City, Country)</label>
            <Controller
              name="currentLocation"
              control={control}
              render={({ field }) => (
                <AsyncSelect
                  theme={getSelectStyles}
                  {...field}
                  cacheOptions
                  // value={}
                  loadOptions={loadOptions} // Correctly typed loadOptions
                  defaultOptions
                  className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  // onChange={(selectedOption) =>
                  //   field.onChange(selectedOption?.value)
                  // } // Handle onChange
                  placeholder="Select"
                  isClearable
                  isSearchable
                />
                // <Select theme={getSelectStyles}
                //   {...field}
                //   className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "

                //   options={[
                //     { value: "New York, USA", label: "New York, USA" },
                //     { value: "Toronto, Canada", label: "Toronto, Canada" },
                //     // Add more options
                //   ]}
                //   isSearchable
                //   placeholder="Search and Select"
                // />
              )}
            />
            <p className="text-[12px] text-red-600 pt-1">
              {formState.errors.currentLocation?.message as string}
            </p>
          </div>
          <div className="mt-3">
            <label>Driving License</label>
            <Controller
              name="drivingLicense.hasLicense"
              control={control}
              render={({ field }) => (
                <Select
                  theme={getSelectStyles}
                  className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  {...field}
                  options={[
                    {
                      value: "true", // Use the country name as the value
                      label: "Yes",
                    },
                    {
                      value: "false", // Use the country name as the value
                      label: "No",
                    },
                  ]}
                  placeholder="Select"
                  isClearable
                />
              )}
            />

            {watchLicense && (
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mt-3">
                <div className="">
                  <label className="">Type of driving licence</label>
                  <Controller
                    name="drivingLicense.type"
                    control={control}
                    render={({ field }) => (
                      <Select
                        theme={getSelectStyles}
                        className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                        {...field}
                        options={licType}
                        placeholder="Select"
                        isClearable
                      />
                    )}
                  />
                  <p className="text-[12px] text-red-600 pt-1">
                    {licenseError?.type?.message as string}
                  </p>
                </div>
                <div className="">
                  <label className="">Country of driving licence</label>
                  <Controller
                    name="drivingLicense.country"
                    control={control}
                    render={({ field }) => (
                      <Select
                        theme={getSelectStyles}
                        className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                        {...field}
                        options={countries}
                        isSearchable
                        placeholder="Search and Select"
                        isClearable
                      />
                    )}
                  />
                  <p>{(licenseError?.country?.message as string) ?? ""}</p>
                </div>
              </div>
            )}
          </div>

          <div className="mt-3">
            <label className="">Visa Status</label>
            <Controller
              name="visaStatus"
              control={control}
              render={({ field }) => (
                <Select
                  theme={getSelectStyles}
                  className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  {...field}
                  options={visa}
                  placeholder="Select"
                  isClearable
                />
              )}
            />
            <p className="text-[12px] text-red-600 pt-1">
              {(formState?.errors?.visaStatus?.message as string) ?? ""}
            </p>
          </div>
        </div>
        <br />
        {/* ///////////////////////////////////////////////// */}
        <div className=" p-4 border border-gray-200 rounded-3xl my-3">
          <h2 className="text-xl font-bold text-center">Contact Details</h2>
          <div className="mt-3">
            <label>Mobile Number</label>
            <input
              {...register("mobileNumber")}
              className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
              type="text"
              placeholder="Mobile Number"
            />
            <p className="text-[12px] text-red-600 pt-1">
              {formState.errors.mobileNumber?.message as string}
            </p>
          </div>
          <div className="mt-3">
            <label>WhatsApp Number</label>
            <input
              {...register("whatsappNumber")}
              className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
              type="text"
              placeholder="Whatsapp Number"
            />
            <p className="text-[12px] text-red-600 pt-1">
              {formState.errors.whatsappNumber?.message as string}
            </p>
          </div>

          <div className="mt-3">
            <label>Email Address</label>
            <input
              {...register("email")}
              className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
              type="email"
              placeholder="Email Address"
            />
            <p className="text-[12px] text-red-600 pt-1">
              {formState.errors.email?.message as string}
            </p>
          </div>

          <div className="mt-3">
            <label>LinkedIn Profile URL (https://url.com)</label>
            <input
              {...register("linkedin")}
              className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
              type="text"
              placeholder="Profile Url"
            />
            <p className="text-[12px] text-red-600 pt-1">
              {formState.errors.linkedin?.message as string}
            </p>
          </div>
        </div>
        <br />
        {/* /////////////////////////////////////////////// */}
        <div className=" p-4 border border-gray-200 rounded-3xl my-3">
          <h2 className="text-xl font-bold text-center">
            Professional Profile
          </h2>

          {/* <div className="mt-3">
            <label>Highest Qualification</label>
            <Select theme={getSelectStyles}
              {...register("highestQualification")}
              className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
            >
              <option value="">Select Qualification</option>
              <option value="Bachelor’s">Bachelor’s</option>
              <option value="Master’s">Master’s</option>
              <option value="PhD">PhD</option>
            </select>
            <p className="text-[12px] text-red-600 pt-1">{formState.errors.highestQualification?.message as string}</p>
          </div> */}
          <div className="mt-3">
            <label className="">Highest Qualification</label>
            <Controller
              name="highestQualification"
              control={control}
              render={({ field }) => (
                <Select
                  theme={getSelectStyles}
                  className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  {...field}
                  options={qual}
                  isClearable
                  placeholder="Search and Select"
                />
              )}
            />
            <p className="text-[12px] text-red-600 pt-1">
              {(formState?.errors?.highestQualification?.message as string) ??
                ""}
            </p>
          </div>
          <div className="mt-5">
            <div className="flex items-center space-x-2">
              <input
                {...register("qualOther")}
                type="checkbox"
                className="w-5 h-5 border rounded-md text-primary focus:ring-2 focus:ring-primary focus:outline-none dark:text-gray-900"
              />
              <span className="">Could not find your qualification?</span>
            </div>
            {/* <p className="text-[12px] text-red-600 pt-1">{formState.errors.fieldOfSpecialization?.message as string}</p> */}
          </div>

          {watch("qualOther") && (
            <div className="mt-3">
              <label className="block mb-2">
                Please specify your other qualifications:
              </label>
              <input
                {...register("otherQualification")}
                className="w-full p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                type="text"
                placeholder="Specify Qualification"
              />
              <p className="text-red-600 pt-1-500">
                {formState.errors.otherQualification?.message as string}
              </p>
            </div>
          )}
          <div className="mt-3">
            <label>Specialization</label>
            <input
              {...register("fieldOfSpecialization")}
              className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
              type="text"
              placeholder="Specialization"
            />
            <p className="text-[12px] text-red-600 pt-1">
              {formState.errors.fieldOfSpecialization?.message as string}
            </p>
          </div>

          {/* <div className="mt-3">
            <label>Years of Relevant Experience</label>
            <Select theme={getSelectStyles}
              {...register("experience")}
              className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
            >
              <option value="">Select Years of Experience</option>
              {[...Array(16).keys()].map((i) => (
                <option key={i} value={i + 1}>
                  {i + 1} {i === 15 && "+"}
                </option>
              ))}
            </select>
            <p className="text-[12px] text-red-600 pt-1">{formState.errors.experience?.message as string}</p>
          </div> */}
          <div className="mt-3">
            <label className="">Years of Relevant Experience</label>
            <Controller
              name="experience"
              control={control}
              render={({ field }) => (
                <Select
                  theme={getSelectStyles}
                  className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  {...field}
                  options={experience}
                  isClearable
                  placeholder="Search and Select"
                />
              )}
            />
            <p className="text-[12px] text-red-600 pt-1">
              {(formState?.errors?.experience?.message as string) ?? ""}
            </p>
          </div>
          <div className="mt-3">
            <label>
              Certifications/Professional Qualifications (CIPS, CSCP etc)
            </label>
            <input
              {...register("certifications")}
              className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
              type="text"
              placeholder="Certification"
              // placeholder="CIPS, CSCP etc"
            />
          </div>

          <div className="mt-3">
            <label>Current Position (Job Title)</label>
            <input
              {...register("currentPosition")}
              className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
              type="text"
              placeholder="Current Position"
            />
            <p className="text-[12px] text-red-600 pt-1">
              {formState.errors.currentPosition?.message as string}
            </p>
          </div>

          <div className="mt-3">
            <label>Current Organization (Name of the company)</label>
            <input
              {...register("currentOrganization")}
              className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
              type="text"
              placeholder="Current Organization"
            />
            <p className="text-[12px] text-red-600 pt-1">
              {formState.errors.currentOrganization?.message as string}
            </p>
          </div>

          {/* <div className="mt-3">
            <label>Industry Expertise</label>
            <Controller
              name="industryExpertise"
              control={control}
              render={({ field }) => (
                <Select theme={getSelectStyles}
                  {...field}
                  options={[
                    { value: "Manufacturing", label: "Manufacturing" },
                    { value: "Retail", label: "Retail" },
                    { value: "Real Estate", label: "Real Estate" },
                    { value: "Healthcare", label: "Healthcare" },
                  ]}
                  isMulti
                  placeholder="Select Industry Expertise"
                />
              )}
            />
            <p className="text-[12px] text-red-600 pt-1">{formState.errors.industryExpertise?.message as string}</p>
          </div> */}
          <div className="mt-3">
            <label className="">Industry Expertise</label>
            <Controller
              name="industryExpertise"
              control={control}
              render={({ field }) => (
                <Select
                  theme={getSelectStyles}
                  className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  {...field}
                  options={expert}
                  isClearable
                  isSearchable
                  isMulti
                  placeholder="Multiple Select"
                />
              )}
            />
            <p className="text-[12px] text-red-600 pt-1">
              {(formState?.errors?.experience?.message as string) ?? ""}
            </p>
          </div>
          {watchExpert && (
            <div className="mt-3">
              <label>Specify Industry Expertise</label>
              <input
                {...register("otherExpertise")}
                className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                type="text"
                placeholder="Specify Experience"
              />
              <p className="text-[12px] text-red-600 pt-1">
                {formState.errors.otherExpertise?.message as string}
              </p>
            </div>
          )}
          <div className="mt-3">
            <label className="">Areas of Expertise</label>
            <Controller
              name="areasOfExpertise"
              control={control}
              render={({ field }) => (
                <Select
                  theme={getSelectStyles}
                  className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  {...field}
                  options={area}
                  isClearable
                  isSearchable
                  isMulti
                  placeholder="Multiple Select"
                />
              )}
            />
            <p className="text-[12px] text-red-600 pt-1">
              {(formState?.errors?.areasOfExpertise?.message as string) ?? ""}
            </p>
          </div>
          {watchArea && (
            <div className="mt-3">
              <label>Specify area of expertise</label>
              <input
                {...register("otherArea")}
                className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                type="text"
                placeholder="Specify Area of Expertise"
              />
              <p className="text-[12px] text-red-600 pt-1">
                {formState.errors.otherArea?.message as string}
              </p>
            </div>
          )}
          {/* <div className="mt-3">
            <label>Areas of Expertise</label>
            <Controller
              name="areasOfExpertise"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={[
                    {
                      value: "Strategic Sourcing",
                      label: "Strategic Sourcing",
                    },
                    { value: "Vendor Management", label: "Vendor Management" },
                    {
                      value: "Contract Negotiation",
                      label: "Contract Negotiation",
                    },
                    {
                      value: "Inventory Management",
                      label: "Inventory Management",
                    },
                  ]}
                  isMulti
                  placeholder="Select Areas of Expertise"
                />
              )}
            />
            <p className="text-[12px] text-red-600 pt-1">{formState.errors.areasOfExpertise?.message as string}</p>
          </div> */}
        </div>
        {/* //////////////////////////// */}
        <br />
        <div className=" p-4 border border-gray-200 rounded-3xl my-5">
          <h2 className="text-xl font-bold text-center">Documents Upload</h2>
          <div className="flex justify-evenly w-full items-center mt-3">
            <label>Resume/CV</label>
            {/* <div className="mt-3">
            
            <input
              {...register("resume")}
              className="w-full  p-2 mt-3 text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
              type="file"
              // name={file}
              accept=".pdf,.doc,.docx"
            />
            <p className="text-[12px] text-red-600 pt-1">{formState.errors.resume?.message as string}</p>
          </div> */}
            <div>
              <Controller
                name="resume"
                control={control}
                render={({ field }) => (
                  <input
                    onChange={(e) => {
                      setValue("resume", e.target.files);
                    }}
                    className="w-[100px]    text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                    type="file"
                    accept=".pdf,.doc,.docx"
                  />
                )}
              />
              <p className="text-[12px] text-red-600 pt-1">
                {formState.errors.resume?.message as string}
              </p>
              {file && <label className="">{file}</label>}
            </div>
          </div>
        </div>

        {/* Continue other fields similarly */}

        <br />
        <div className="w-full flex justify-center mt-5">
          <div className="">
            <button
              type="button"
              onClick={handleSubmit(onSubmit)}
              className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
            >
              <span className="relative text-sm font-semibold text-white">
                Submit
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
