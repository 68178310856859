import React from "react";
import Container from "../../app/components/Container";
import { routePaths } from "../../routes/paths";
import { Link } from "react-router-dom";
import fullLogo from "../../assets/fullLogo.png";
import fullLogoDark from "../../assets/fullLogoDark.png";

// Define the footer component in TypeScript
const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer
        className="py-10 md:py-10 "
        style={{ borderTop: "1px solid #e3e3e3" }}
      >
        <Container>
          <div className="mx-2">
            <div className="flex flex-wrap items-center justify-evenly md:flex-nowrap w-full">
              <div className="hidden md:block mt-5 md:w-1/2 w-full">
                <div className="flex items-center">
                  <img
                    src={fullLogo}
                    alt=""
                    className="w-[236px] dark:hidden filter contrast-125 "
                  />
                  <img
                    src={fullLogoDark}
                    alt=""
                    className="w-[236px] hidden dark:block filter contrast-125 "
                  />
                  {/* <img src="/iconic2.png" alt="" className="w-14 dark:hidden filter contrast-125 " />
                  <img
                    src="/logo.png"
                    alt=""
                    className="w-14 hidden dark:block filter contrast-125 "
                  /> */}

                  {/* <span className="text-xl font-bold text-gray-900 dark:text-white pl-2">
                    <span className="text-primary">Strategic</span> Sourcing
                    Middle East
                  </span> */}
                </div>
              </div>
              <div className="flex w-full justify-center space-x-12 text-gray-600 dark:text-gray-300 sm:w-3/12">
                <ul className="list-inside list-disc space-y-8">
                  <li>
                    <Link to="/" className="transition hover:text-primary">
                      Home
                    </Link>
                  </li>

                  <li>
                    <Link to="/about" className="transition hover:text-primary">
                      About
                    </Link>
                  </li>
                  
                </ul>
              </div>
              <div className="flex w-full justify-center space-x-12 text-gray-600 dark:text-gray-300 sm:w-4/12">
                <ul className="list-inside list-disc space-y-6">
                  <li>
                    <Link to="./programs" className="transition hover:text-primary">
                      Programs & events
                    </Link>
                  </li>

                  <li>
                    <Link to="/updates" className="transition hover:text-primary">
                      Insights & updates
                    </Link>
                  </li>
                  <li>
                    <Link to="/careers" className="transition hover:text-primary">
                      Careers
                    </Link>
                  </li>

                 
                  
                </ul>
              </div>
              <div className="m-auto mt-16 w-10/12 space-y-6 text-center sm:mt-auto sm:w-3/12 sm:text-left">
                <span className="flex justify-between text-gray-600 dark:text-white">
                  <Link
                    to={routePaths.TERMS_AND_CONDITIONS}
                    className="font-medium transition hover:text-primary"
                    rel="noopener noreferrer"
                    // className="font-medium"
                  >
                    Terms of Use{" "}
                  </Link>
                  {/* <Link
                    to={routePaths.PRIVACY_POLICY}
                    // target="_blank"
                    rel="noopener noreferrer"
                    className="font-medium transition hover:text-primary"
                  >
                    {" "}
                    Privacy Policy
                  </Link> */}
                </span>
                {/* <span className="flex flex-col md:flex-row justify-between text-gray-600 dark:text-white"> */}

                <span className="block  text-gray-500 dark:text-gray-400">
                  Need help?{" "}
                  <Link
                    to={routePaths.CONTACT_US}
                    className="font-semibold text-gray-600 dark:text-white"
                  >
                    {" "}
                    Contact Us
                  </Link>
                </span>
                <span className="block  text-gray-500 dark:text-gray-400">
                  
                  <Link
                    to={routePaths.PRIVACY_POLICY}
                    className="font-medium text-gray-600 dark:text-white"
                  >
                    {" "}
                    Privacy Policy
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="text-center pt-6">
            <span className="block text-gray-500 dark:text-gray-400">
              Strategic Sourcing Middle East &copy;{" "}
              <span id="year">{currentYear}</span>
            </span>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
