import React from "react";
import "./popup2.css";
import parse from "html-react-parser";
import SimpleSlider from "../slider/slider";

interface PopupProps {
  setOpen: (value: boolean) => void;
  data: any;
}
const Popup2: React.FC<PopupProps> = ({ setOpen, data }) => {
  return (
    <div className="modal z-50">
      <article className="modal-container">
        <header className="modal-container-header bg-primary text-white">
          <h1 className="modal-container-title">{data?.name}</h1>
          <button
            className="icon-button"
            onClick={() => {
              setOpen(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                fill="currentColor"
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
              />
            </svg>
          </button>
        </header>
        <section className="modal-container-body rtf">
          <div
            className=" w-full "
            //   data-aos="zoom-in-up"
          >
            <SimpleSlider>
              {data?.gallery?.map((item: any, i: number) => (
                <img
                  src={item?.image}
                  key={i}
                  className="update-popup-image  "
                />
              ))}
            </SimpleSlider>
          </div>
          <br />
          <div className=" my-2 text-left border-t">
            {/* <p className="my-2 text-gray-700  w-full max-w-md h-24 overflow-hidden text-ellipsis whitespace-normal leading-6"> */}
            <p className="my-2 text-gray-700  w-full max-w-md h-24 line-clamp-4">
              {data?.description
                ? parse(data?.description)
                : "No data to display"}
            </p>
            {/* <p className="text-primary readMore">...Read More</p> */}
          </div>
        </section>
        <footer className="modal-container-footer">
          <div
            // to={"/"}
            onClick={() => setOpen(false)}
            className="relative cursor-pointer flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
          >
            <span className="relative text-sm font-semibold text-white">
              Close
            </span>
          </div>
        </footer>
      </article>
    </div>
  );
};

export default Popup2;
