
import { RouteObject } from "react-router-dom";


export type RouteObjectWithSlug = (RouteObject & { slug: string } & { children?: RouteObjectWithSlug[] })
export const routes: RouteObjectWithSlug[] = [
  // {
  //   path: "/dashboard",
  //   element: <AboutPage />,
    // children: [
    //   { path: "home", element: <DashboardHome /> },
    //   { path: "settings", element: <DashboardSettings /> },
    // ],
  // },
];
