import React, { useEffect, useState } from "react";
import Container from "../../../../components/Container";
import "./index.css";
import { Link } from "react-router-dom";
import Spacer from "../../../../components/Spacer";

const HeroSection: React.FC = () => {
  const leftMessages = [
    {
      color: "text-[#fb9331]",
      title: "Supplier Relationships",
      description: "Building strong partnerships with suppliers",
    },
    {
      color: "text-[#ffe609]",
      title: "Cost Optimization",
      description: "Reducing expenses while maintaining quality",
    },
    {
      color: "text-[#a4d840]",
      title: "Competitive Advantage",
      description: "Gaining market edge through strategic sourcing",
    },
    // {
    //   color: "text-[#3fdb8f]",
    //   title: "Supply Chain Performance",
    //   description: "Enhancing supply chain operations and outcomes",
    // },
    {
      color: "text-[#18c6fe]",
      title: "Streamlining",
      description: "RSimplifying procurement process for efficiency",
    },
    {
      color: "text-[#fe6a65]",
      title: "Sustainable Practices",
      description: "RImplementing eco-friendly and sustainable methods",
    },
  ];
  const [index, setIndex] = useState<number>(0);
  const [rotation, setRotation] = useState(0);
  const handleRotate = () => {
    setRotation((prevRotation) => prevRotation + 60);
    setIndex((prevIndex) => (prevIndex + 1) % leftMessages.length);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      handleRotate();
    }, 300);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotation]);
  // console.log(index);
  return (
    <div className="">
      <div className="relative" id="home">
        <div
          aria-hidden="true"
          className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
        >
          <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
          <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600"></div>
        </div>
        <Container>
          <div
            className="relative pt-36 ml-auto "
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <div className="lg:w-31/2 text-center mx-auto">
              <h1
                className="text-gray-900 dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                Welcome to{" "}
                <span
                  className="block text-primary dark:text-white"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  Strategic Sourcing Middle East
                </span>
              </h1>
              <p
                className="mt-8 text-gray-700 dark:text-gray-300"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                At Strategic Sourcing Middle East, we believe procurement is
                more than just a function, it's a catalyst for business
                excellence. We partner with organizations globally to streamline
                procurement, elevate supply chain performance, and drive
                competitive advantage through strategic sourcing.
              </p>
              <div
                className="mt-16 flex flex-wrap justify-center gap-y-4 gap-x-6"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <Link
                  to="/about"
                  className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                >
                  <span className="relative text-base font-semibold text-white">
                    About Us
                  </span>
                </Link>
                <Link
                  to="/contact"
                  className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:border before:border-transparent before:bg-primary/10 before:bg-gradient-to-b before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 dark:before:border-gray-700 dark:before:bg-gray-800 sm:w-max"
                >
                  <span className="relative text-base font-semibold text-primary dark:text-white">
                    Contact Us
                  </span>
                </Link>
              </div>
              {/* <div className=" sm:flex flec-col justify-center w-100 items-center sectional-container2 py-10">
                <div className={`font-semibold w-72 text-center px-10`}>
                  <h3 className={`${leftMessages[index].color} text-2xl`}>
                    {leftMessages[index].title}
                  </h3>
                  <p className="dark:text-white py-3">
                    {leftMessages[index].description}
                  </p>
                </div> */}
              {/* <div className="perspective">
                  <div className="relative w-38 h-full">
                    <img
                      src="./images/clients/rotate.png"
                      alt="Rotating"
                      style={{ transform: `rotateZ(${rotation}deg)` }}
                      className="w-full h-auto rounded-lg transition-transform duration-700 filter contrast-125 "
                    />
                  </div>
                </div> */}
              {/* <div className={`font-semibold w-72 text-center px-10`}>
                  <h3 className={`${rightMessages[index].color} text-2xl`}>
                    {rightMessages[index].title}
                  </h3>
                  <p className="dark:text-white py-3">
                    {rightMessages[index].description}
                  </p>
                </div> */}
              {/* </div>
               */}
              <Spacer />
              <div className="  flex flex-col md:flex-row items-center justify-evenly  border-b-gray-100 sm:grid-cols-2  py-10 sectional-container">
                {leftMessages.map((item, i) => (
                  <div
                    key={i}
                    className={`transition-transform duration-500 w-full hover:-translate-y-2 m-2 ${
                      index === i ? "-translate-y-7" : ""
                    }`}
                  >
                    <div
                      key={i}
                      className={`w-full transition duration-200 relative flex h-11  items-center justify-center px-6 before:absolute before:inset-0 before:rounded-xl before:border before:border-transparent before:bg-primary/10 before:bg-gradient-to-b before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 dark:before:border-gray-700 dark:before:bg-gray-800 sm:w-full`}
                    >
                      <div className="relative text-base font-semibold text-primary ">
                        {item.title}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <Spacer />
              {/* <div className="hidden py-10 border-y border-gray-100 dark:border-gray-800 sm:flex justify-between  ">
                <div
                  className="text-center pr-3"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                >
                  <h6 className="text-lg font-semibold text-gray-700 dark:text-white">
                    Our Commitment
                  </h6>
                  <p className="mt-2 text-gray-500">
                    We aspire to be recognized as a global leader in procurement
                    consultancy. With a commitment to excellence and an
                    extensive vendor network, we aim to be the partner of choice
                    for organizations seeking to elevate their sourcing
                    strategies and achieve sustainable growth.
                  </p>
                </div>
                <div className="text-center ">
                  <h6 className="text-lg font-semibold text-gray-700 dark:text-white">
                    Our Aspirations
                  </h6>
                  <p className="mt-2 text-gray-500">
                    Our mission is to empower organizations through exceptional
                    procurement consultancy services. We focus on optimizing
                    supply chains, reducing costs, and enabling our clients to
                    achieve their strategic objectives. By delivering innovative
                    sourcing solutions tailored to individual needs, we position
                    ourselves as trusted partners in our clients' pursuit of
                    operational excellence and sustainable growth.
                  </p>
                </div>
                <div
                  className="text-center pl-3"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <h6 className="text-lg font-semibold text-gray-700 dark:text-white">
                    Our Global Footprint
                  </h6>
                  <p className="mt-2 text-gray-500">
                    Strategic Sourcing Middle East operates on a global scale,
                    with a strong presence across key international markets. Our
                    expansive network of vendors allows us to source
                    high-quality suppliers and resources tailored to the
                    specific needs of our clients, regardless of their industry
                    or location.
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default HeroSection;
