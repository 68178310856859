import React from "react";

const About: React.FC = () => {
  return (
    <div
      className=" text-center flex justify-center "
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
    >
      <div className="container">
        <div className="welcome-content text-center flex">
          {/* <div className="w-full pt-3  flex items-center justify-center">
            <div className="border-b-2 hidden md:block border-b-black w-[100px] dark:border-b-white "></div>
          </div> */}
          <div className="w-full">
            <h1 className="font-medium text-3xl  text-gray-900 dark:text-white p-3 ">
              Careers
            </h1>
          </div>
          {/* <div className="w-full pt-3  flex items-center justify-center">
            <div className="border-b-2 hidden md:block border-b-black w-[100px] dark:border-b-white "></div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default About;
