import React from "react";
// import Back from "../../../../../assets/hero.jpeg";
import "./index.css";

interface ProgProps {
  data: any;
}
const Cards: React.FC<ProgProps> = ({ data }) => {
  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-2 grid-cols-1 text-center sectional-container ">
      {data?.gallery?.length === 0 ? (
        <>
          <div
            className="col-span-1 md:col-span-2 lg:col-span-2"
            data-aos="zoom-in-up "
          >
            <div className=" relative h-full ">
              <img
                src={data?.image}
                // src={Back}
                alt=""
                // height={200}
                className="programDetail-card-image max-h-[calc(100vh-300px)] w-full"
              />
              {/* <div className="p-10 bg-primary"></div> */}
            </div>
          </div>
        </>
      ) : (
        <>
          {data?.gallery?.map((item, i) => (
            <div className="" data-aos="zoom-in-up">
              <div className=" relative h-full flex">
                <img
                  src={item.image}
                  // src={Back}
                  alt=""
                  // height={200}
                  className="programDetail-card-image max-h-[326px] w-[100%]"
                />
                {/* <div className="p-10 bg-primary"></div> */}
              </div>
            </div>
          ))}
        </>
      )}

      {/* <div className="">
      <div className=" relative h-full">
          <img
            src={data?.image}
            // src={Back}
            alt=""
            // height={200}
            className="programDetail-card-image "
          />
          <div className="p-10 bg-primary"></div>
        </div>
       
        
      </div> */}
    </div>
  );
};

export default Cards;
