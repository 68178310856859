import React from "react";
import ContactForm from "./form/ContactForm";
import Container from "../../../components/Container";

const Contact: React.FC = () => {
  return (
    // <div className="min-h-screen bg-gray-100 flex items-center justify-center p-6">
    //   <ContactForm />
    // </div>
    <div className="py-5 ">
      <div className="relative " id="contact">
        <div
          aria-hidden="true"
          className="absolute z-0 inset-0 grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
        >
          <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700  z-0 "></div>
          <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600  z-0 "></div>
        </div>{" "}
      </div>
      <div className=" flex items-center justify-center py-40 min-h-screen">
        <Container>
          <div className="space-y-6 justify-between p z-1 text-gray-600 lg:flex  md:gap-6 md:space-y-0 lg:gap-12 lg:items-center">
            <div className="md:7/12 lg:w-1/2 ">
              <h2 className="text-3xl font-bold text-gray-900 md:text-4xl dark:text-white">
                Contact Us
              </h2>
              <p className="my-8 text-gray-600 dark:text-gray-300">
                We value your inquiries and feedback. Whether you have
                questions, need support, or wish to share your thoughts, please
                fill out the form below. Our team will respond promptly to
                address your needs effectively.
                <br /> <br /> Thank you for reaching out to us.
              </p>
            </div>
            <div className="md:5/12 lg:w-1/2 ">
              <ContactForm />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Contact;
