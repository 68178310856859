export const features = [
    {
        name : "Vendor Management"
    },
    {
        name : "Contract Negotiation"
    },
    {
        name : "Supply Chain Optimization"
    },
    {
        name : "Risk Assessment"
    },
    {
        name : "Category Management"
    },
    {
        name : "Strategic Sourcing"
    },
    {
        name : "Spend Analysis"
    },
    {
        name : "Supplier Performance Evaluation"
    },
    {
        name : "Sustainable Procurement"
    },
    {
        name : "E-Procurement Solutions"
    },
    {
        name : "Procurement Training & Development"
    },
    {
        name : "Inventory Management & Optimization"
    },

]