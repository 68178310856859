import React from "react";

const About: React.FC = () => {
  return (
    <div
      className=" text-center flex justify-center "
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
    >
      <div className="container">
        <div className="welcome-content text-center ">
          {/* <h5 className="font-medium text-xl text-gray-600 dark:text-gray-300">
            Program Details
          </h5> */}
          <h1 className="font-medium text-3xl text-gray-900 dark:text-white p-3 ">
            Registration
          </h1>
          <div className="w-full pt-3 flex  justify-center">
            <div className="border-b-2  border-b-black w-[100px] dark:border-b-white "></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
