import React, { useEffect, useState } from "react";
import { setUser } from "../utils/auth";

import Loader from "../app/components/Loaders";

// Define type for children prop (assuming React.FC)
interface MainWrapperProps {
  children: React.ReactNode;
}

export const MainWrapper: React.FC<MainWrapperProps> = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handler = async () => {
      setLoading(true);
      await setUser();
      // Assuming this is an async function that sets the user data

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };

    handler();
  }, []);

  return (
    <>
      <div style={{ height: "100dvh" }}>{loading ? <Loader /> : children}</div>
    </>
  );
};
