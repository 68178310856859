import React from "react";

const Cards:React.FC = () => {
  return (
    
    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 text-center sectional-container">
    <div className="hover:shadow-glow group p-6 sm:p-8 rounded-3xl bg-white border border-gray-100 dark:shadow-none dark:border-gray-700 dark:bg-gray-800 bg-opacity-50 shadow-2xl shadow-gray-600/10"  data-aos="zoom-in-up">
        
        <div className="mt-6 relative">
            <h3 className="text-2xl font-semibold text-gray-800 dark:text-white">
            Our Commitment
            </h3>
            <p className="mt-6 mb-8 text-gray-600 dark:text-gray-300">
            We aspire to be recognized as a global leader in procurement consultancy. With a commitment to excellence and an extensive vendor network, we aim to be the partner of choice for organizations seeking to elevate their sourcing strategies and achieve sustainable growth.
            </p>
           
        </div>

    </div>
    <div className="hover:shadow-glow group p-6 sm:p-8 rounded-3xl bg-white border border-gray-100 dark:shadow-none dark:border-gray-700 dark:bg-gray-800 bg-opacity-50 shadow-2xl shadow-gray-600/10"  data-aos="zoom-in-up">
        
        <div className="mt-6 relative">
            <h3 className="text-2xl font-semibold text-gray-800 dark:text-white">
            Our Aspirations
            </h3>
            <p className="mt-6 mb-8 text-gray-600 dark:text-gray-300">
            Our mission is to empower organizations through exceptional procurement consultancy services. We focus on optimizing supply chains, reducing costs, and enabling our clients to achieve their strategic objectives. By delivering innovative sourcing solutions tailored to individual needs, we position ourselves as trusted partners in our clients' pursuit of operational excellence and sustainable growth.
            </p>
           
        </div>

    </div>
    <div className="hover:shadow-glow group p-6 sm:p-8 rounded-3xl bg-white border border-gray-100 dark:shadow-none dark:border-gray-700 dark:bg-gray-800 bg-opacity-50 shadow-2xl shadow-gray-600/10"  data-aos="zoom-in-up">
       
        <div className="mt-6 relative">
            <h3 className="text-2xl font-semibold text-gray-800 dark:text-white">
            Our Global Footprint
            </h3>
            <p className="mt-6 mb-8 text-gray-600 dark:text-gray-300">
            Strategic Sourcing Middle East operates on a global scale, with a strong presence across key international markets. Our expansive network of vendors allows us to source high-quality suppliers and resources tailored to the specific needs of our clients, regardless of their industry or location.
            </p>
           
        </div>

    </div>
</div>
  );
};

export default Cards;
