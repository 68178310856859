import React from 'react'

const Loader:React.FC = () => {
    return (
        <div className='loader-container'>
        <div className="loader">
            <div className="loader_cube loader_cube--color"></div>
            <div className="loader_cube loader_cube--glowing"></div>
        </div>
        </div>
    )
}

export default Loader