import React from "react";
import "./updateHome.css";
// import Eye from "../../../../../assets/svg/eye";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";

interface UpProps {
  data: any;
  open: boolean;
  setOpen: (value: boolean) => void;
  setClickedData: (data: any) => void;
}

const UpdateCards: React.FC<UpProps> = ({ data, setOpen, setClickedData }) => {
  const navigate = useNavigate();
  const handleNav = (id: string) => {
    navigate(`/updates/${id}`);
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div
        className="max-w-[calc(100vw-80px)] rounded-3xl shadow-lg"
        data-aos="zoom-in-up"
      >
        <img
          src={data?.image}
          className="programDetail-card-image rounded-3xl "
        />
      </div>

      <div
        className="col-span-1 border border-gray-100  pl-4 flex flex-col items-start h-full justify-evenly"
        // data-aos="zoom-in-up"
      >
        <div
          className=" text-primary rounded-3xl  text-2xl"
          
        >
          <div className="text-center text-truncate capitalize">
            {data?.name}
          </div>
        </div>
        <div className="  text-left ">
          {/* <p className="my-2 text-gray-700  w-full max-w-md h-24 overflow-hidden text-ellipsis whitespace-normal leading-6"> */}
          <p className="my-2  dark:text-gray-100 venturi text-gray-700 w-full max-w-md h-30  line-clamp-4 ">
            {data?.description
              ? parse(data?.description)
              : "No data to display"}
            {/* Our mission is to empower organizations through exceptional
              procurement consultancy services. We focus on optimizing supply
              chains, reducing costs, and enabling our clients to achieve their
              strategic objectives. By delivering innovative sourcing solutions
              tailored to individual needs, we position ourselves as trusted
              partners in our clients' pursuit of operational excellence and
              sustainable growth. */}
          </p>
        </div>

        <div className="w-full  flex justify-start ">
          <div className="">
            <div
              // to={"/"}
              // onClick={() => handleNav()}
              onClick={() => handleNav(data?.id)}
              className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
            >
              <span className="relative text-sm font-semibold text-white">
                Read More
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCards;
