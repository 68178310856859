import React from "react";

// Define types for SEO data
export interface SeoData {
    title: string;
    description: string;
    keywords: string;
}

// Define the shape of the SEO context
export interface SeoContextType {
    updateSeoData: (title: string, description: string, keywords: string) => void;
}

// Create the SEO context
export const SeoContext = React.createContext<SeoContextType | undefined>(undefined);

// Custom hook to access SEO context
export const useSeo = (): SeoContextType => {
    const context = React.useContext(SeoContext);
    if (!context) {
        throw new Error("useSeo must be used within a SeoProvider");
    }
    return context;
};
