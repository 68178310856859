import React, { useEffect, useRef } from "react";
import About from "./components/About";
import Cards from "./components/Cards";
import Container from "../../../components/Container";
import Whatdo from "./components/Whatdo";
import Team from "./components/Team";
import Spacer from "../../../components/Spacer";

const AboutPage: React.FC = () => {
  const sectionRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];
  const sectionSubRefs1 = useRef<HTMLDivElement>(null);
  const sectionSubRefs2 = useRef<HTMLDivElement>(null);
  const isScrolling = useRef(false); // Prevent multiple scroll events

  const handleScroll = (e: any) => {
    if (isScrolling.current) return;

    const currentScroll = window.scrollY;
    const viewportHeight = window.innerHeight;
    if (sectionSubRefs1?.current) {
      if (
        sectionSubRefs1?.current?.getBoundingClientRect()?.height >
        viewportHeight
      )
        return;
    }
    if (sectionSubRefs2.current) {
      if (
        sectionSubRefs2?.current?.getBoundingClientRect()?.height >
        viewportHeight
      )
        return;
    }

    // Determine the current section index
    const currentSectionIndex = Math.round(currentScroll / viewportHeight);
    const isScrollingDown = e.deltaY > 0;

    // Calculate next section index
    let nextSectionIndex = isScrollingDown
      ? currentSectionIndex + 1
      : currentSectionIndex - 1;

    // Limit index to valid range
    nextSectionIndex = Math.max(
      0,
      Math.min(nextSectionIndex, sectionRefs.length)
    );

    // Scroll to the next section
    const nextElement = sectionRefs[nextSectionIndex]?.current;

    if (nextElement) {
      isScrolling.current = true;
      nextElement.scrollIntoView({ behavior: "smooth" });

      // Wait for scroll animation to finish
      setTimeout(() => {
        isScrolling.current = false;
      }, 800);
    }
  };

  useEffect(() => {
    window.addEventListener("wheel", handleScroll);
    return () => window.removeEventListener("wheel", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="relative" id="home">
      <div
        aria-hidden="true"
        className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
      >
        <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
        <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600"></div>
      </div>{" "}
      <div
        className="flex items-center justify-center flex-col min-h-screen "
        ref={sectionRefs[0]}
      >
        <div className="py-40" ref={sectionSubRefs1}>
          <Container>
            <About />
            <Spacer />
            <Cards />
          </Container>
        </div>
      </div>
      <div
        className="min-h-screen flex items-center justify-center "
        ref={sectionRefs[1]}
      >
        <div className="py-40" ref={sectionSubRefs2}>
          <Container>
            <Whatdo />
            <Spacer />
            <Team />
          </Container>
        </div>
      </div>
    </div>
  );
};
export default AboutPage;
