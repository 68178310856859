import React from "react";
import ReactDOM from "react-dom/client";
import StrategicSourcingWebApp from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./styles/style.scss";
import { SeoProvider } from "./seo/SeoProvider";
import { SnackbarProvider } from "notistack";
import ScrollToTop from "./layout/components/ScrollToTop"
import 'react-phone-number-input/style.css'

import "aos/dist/aos.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <SeoProvider>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={5000}
        maxSnack={3}
      >
        <BrowserRouter>
        <ScrollToTop/>
          <StrategicSourcingWebApp />
        </BrowserRouter>
      </SnackbarProvider>
    </SeoProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
