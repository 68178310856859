import React from "react";
import "./card.css";
// import Back from "../../../../../assets/hero.jpeg";
// import Eye from "../../../../../assets/svg/eye";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";

interface ProgProps {
  data: any;
}

const ProgramCards: React.FC<ProgProps> = ({ data }) => {
  const navigate = useNavigate();
  const handleNav = (id: string) => {
    navigate(`/programs/${id}`);
  };
  return (
    <>
      <div className="flex flex-col  items-center w-full max-w-full bg-gray-600 dark:bg-white/70 rounded-3xl ">
        <div
          className="bg-white border border-gray-100 dark:shadow-none dark:border-gray-700 dark:bg-gray-800 shadow-gray-600/10 rounded-t-3xl w-full"
          //   data-aos="zoom-in-up"
        >
          <img src={data?.image} className="program-card-image rounded-t-3xl" />
        </div>
        <div
          className="hover:shadow-glow group  rounded-3xl bg-white border border-gray-100 dark:shadow-none  shadow-2xl shadow-gray-600/10 program-card-content "
          data-aos="zoom-in-up"
        >
          <div
            className="bg-primary text-white rounded-t-3xl  "
            style={{ padding: "7px" }}
          >
            <div className="line-clamp-1 capitalize">{data?.name}</div>
          </div>
          <div className=" m-2 text-left ">
            <div className="text-primary font-bold text-[14px]">
              <div className="flex justify-between">
                <span className="">
                  {data?.start_datetime &&
                    moment(data?.start_datetime).format("DD-MM-YY ")}
                </span>{" "}
                <span>to</span>
                <span className="">
                  {data?.end_datetime &&
                    moment(data?.end_datetime).format("DD-MM-YY ")}
                </span>
              </div>
            </div>
            {/* <p className="my-2 text-gray-700  w-full max-w-md h-24 overflow-hidden text-ellipsis whitespace-normal leading-6"> */}
            <p className="my-1 text-gray-700 venturi w-full max-w-md h-22 min-h-[82px] line-clamp-4 text-[14px]">
              {data?.description
                ? parse(data?.description)
                : "No data to display"}
              {/* Our mission is to empower organizations through exceptional
              procurement consultancy services. We focus on optimizing supply
              chains, reducing costs, and enabling our clients to achieve their
              strategic objectives. By delivering innovative sourcing solutions
              tailored to individual needs, we position ourselves as trusted
              partners in our clients' pursuit of operational excellence and
              sustainable growth. */}
            </p>
            <div className="text-primary text-[12px] line-clamp-1">
              Venue : <span className="font-bold ">{data?.venue}</span>
            </div>
            {/* <p className="text-primary readMore">...Read More</p> */}
          </div>
          {/* <div className="updateEye bg-primary hover:bg-white rounded-full hover:border hover:border-primary text-white hover:text-primary">
          

          <Eye />
        </div> */}
          <div className="w-full  preadMore flex justify-end ">
            <div className="">
              <div
                // to={"/programs/1"}
                onClick={() => handleNav(data?.id)}
                className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
              >
                <span className="relative text-sm font-semibold text-white">
                  Read More
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgramCards;
