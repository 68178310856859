import React from 'react'
import Container from "../../../components/Container";
const PrivacyPolicy: React.FC = () => {
    return (
        <div className='py-5'>
            <div className="relative" id="privacy">
                <div
                    aria-hidden="true"
                    className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
                >
                    <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
                    <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600"></div>
                </div>
             
            </div>
            <div className='py-20'>
                    <Container>
                        <div className='py-40'>
                            <h1 className="font-bold text-3xl text-gray-900 dark:text-white  ">Privacy Policy for Strategic Sourcing Middle East</h1>

                            <div className="mt-8 text-gray-700 dark:text-gray-300 text-lg">
                                <p><strong>Effective Date:</strong> 11/11/2024</p>

                                <p>Strategic Sourcing Middle East ("we," "us," or "our") respects your privacy and is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and disclose personal information obtained through our website located at <a href="https://www.stratsourceme.com" className='text-primary font-bold  cursor-pointer' target='_blank' rel="noreferrer">www.stratsourceme.com</a> (the "Site").</p>

                                <br/><h2 className="font-bold text-2xl">1. Information We Collect</h2>
                                <p>We collect information to provide better services to all users of the Site. The types of information we may collect include:</p>
                                <ul>
                                    <li><strong>Personal Information:</strong> This may include your name, email address, phone number, and other information you provide directly to us through forms, email communications, or other means.</li>
                                    <li><strong>Usage Data:</strong> We automatically collect information about your interactions with the Site, such as your IP address, browser type, pages visited, time spent on pages, and similar details.</li>
                                    <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to enhance your experience on the Site and for analytics purposes. You can manage your cookie preferences in your browser settings.</li>
                                </ul>

                                <br/><h2 className="font-bold text-2xl">2. How We Use Your Information</h2>
                                <p>We use the information collected to operate, improve, and personalize our services, including but not limited to:</p>
                                <ul>
                                    <li>Responding to inquiries and providing customer support.</li>
                                    <li>Managing and processing job applications.</li>
                                    <li>Communicating with you about updates, promotions, and services (you may opt out of marketing communications at any time).</li>
                                    <li>Conducting internal data analysis and website optimization.</li>
                                    <li>Ensuring compliance with our Terms of Use and legal obligations.</li>
                                </ul>

                                <br/><h2 className="font-bold text-2xl">3. How We Share Your Information</h2>
                                <p>We may share your information under certain circumstances, including:</p>
                                <ul>
                                    <li><strong>With Service Providers:</strong> We may share your information with third-party vendors who assist us in providing services such as website hosting, analytics, email delivery, and customer support.</li>
                                    <li><strong>With Business Partners and Clients:</strong> If you are part of our talent network or submit information as a supplier, we may share your details with potential clients or business partners.</li>
                                    <li><strong>In Compliance with Legal Obligations:</strong> We may disclose information if required to do so by law or in response to a valid request from a law enforcement or government agency.</li>
                                </ul>

                                <br/><h2 className="font-bold text-2xl">4. Data Security</h2>
                                <p>We employ appropriate technical and organizational security measures to safeguard your personal information from unauthorized access, use, or disclosure. While we strive to protect your information, no online platform can guarantee complete security. You are responsible for keeping your account credentials secure and notifying us of any suspected security breach.</p>

                                <br/><h2 className="font-bold text-2xl">5. Your Rights and Choices</h2>
                                <p>Depending on your location, you may have certain rights regarding your personal information, including the right to:</p>
                                <ul>
                                    <li>Access, correct, update, or request deletion of your personal information.</li>
                                    <li>Withdraw your consent for processing, where consent is the basis for processing.</li>
                                    <li>Object to or restrict the processing of your personal information.</li>
                                    <li>Request data portability in a commonly used format.</li>
                                </ul>
                                <p>To exercise any of these rights, please contact us at <a href="mailto:hello@stratsourceme.com" className='text-primary font-bold  cursor-pointer'>hello@stratsourceme.com</a>. We may require verification of your identity before fulfilling your request.</p>

                                <br/><h2 className="font-bold text-2xl">6. Retention of Your Information</h2>
                                <p>We retain personal information as long as necessary to fulfill the purposes outlined in this Privacy Policy, comply with legal obligations, and resolve disputes. When your information is no longer required, we will take steps to delete it securely.</p>

                                <br/><h2 className="font-bold text-2xl">7. Third-Party Links</h2>
                                <p>The Site may contain links to other websites or services not operated by Strategic Sourcing Middle East. We are not responsible for the privacy practices or content of third-party websites, and we recommend reviewing the privacy policies of any sites you visit.</p>

                                <br/><h2 className="font-bold text-2xl">8. Children's Privacy</h2>
                                <p>The Site is not intended for children under the age of 13, and we do not knowingly collect personal information from children. If we learn that we have collected personal information from a child under 13 without parental consent, we will take steps to delete such information promptly.</p>

                                <br/><h2 className="font-bold text-2xl">9. Changes to This Privacy Policy</h2>
                                <p>We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated Privacy Policy on the Site and indicating the effective date. Continued use of the Site after changes to the Privacy Policy constitutes acceptance of the updated terms.</p>

                                <br/><h2 className="font-bold text-2xl">10. Contact Us</h2>
                                <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our handling of your personal information, please contact us at:</p>
                                <p>Email: <a href="mailto:hello@stratsourceme.com" className='text-primary font-bold  cursor-pointer'>hello@stratsourceme.com</a></p>
                            </div>
                        </div>
                    </Container>
                </div>
        </div>
    )
}

export default PrivacyPolicy