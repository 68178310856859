import React, { useEffect, useState } from "react";

import Cards from "./components/Cards";
import Container from "../../../components/Container";
import axiosInstance from "../../../../utils/axios";
// import Spacer from "../../../components/Spacer";
import Filter from "./components/Filter";
import SpacerMini from "../../../components/SpacerMini";
import About from "./components/About";
import { Link } from "react-router-dom";
import { routePaths } from "../../../../routes/paths";
import Spacer from "../../../components/Spacer";
import {parseQueryParams} from "../../../data/parse.js"

const Programs: React.FC = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  // Determine the current section index
  const handleSearch = (query: string) => {
    setSearch(query);
  };

  const fetchProgramData = async (search: string) => {
    const payload = {
      search: search,
    };
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/events/list${parseQueryParams(payload)}`
      );
      // console.log(response)
      setData(response?.data?.results);
    } catch (err: any) {}
  };

  useEffect(() => {
    fetchProgramData(search);
  }, [search]);
  return (
    <div className="relative z-0" id="home">
      <div
        aria-hidden="true"
        className="absolute  grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
      >
        <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
        <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600"></div>
      </div>{" "}
      <div className=" min-h-screen ">
        <div className="py-32 w-full">
          <Container>
            <div className="">
              <About />
              <SpacerMini />
              <Filter handleSearch={handleSearch} />
              <Spacer />
              {data?.length !== 0 ? (
                <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center ">
                  {data?.map((item, i) => (
                    <Cards key={i} data={item} />
                  ))}
                </div>
              ) : (
                <div className="not-found relative min-w-max min-h-screen">
                  {/* SVG Graphic */}
                  <svg
                    width="380px"
                    height="500px"
                    viewBox="0 0 837 1045"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <path
                        d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
                        id="Polygon-1"
                        stroke="#007FB2"
                        strokeWidth="6"
                      />
                      <path
                        d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
                        id="Polygon-2"
                        stroke="#EF4A5B"
                        strokeWidth="6"
                      />
                      <path
                        d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
                        id="Polygon-3"
                        stroke="#795D9C"
                        strokeWidth="6"
                      />
                      <path
                        d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
                        id="Polygon-4"
                        stroke="#F2773F"
                        strokeWidth="6"
                      />
                      <path
                        d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
                        id="Polygon-5"
                        stroke="#36B455"
                        strokeWidth="6"
                      />
                    </g>
                  </svg>

                  {/* Error message and buttons */}
                  <div className="message-box dark:text-white text-gray-800">
                    <h1>No data found</h1>
                    {/* <p>No data found </p> */}
                    <div className="buttons-con">
                      <div className="action-link-wrap">
                        <Link
                          to={routePaths.HOME}
                          className="link-button bg-primary text-white "
                        >
                          Go to Home Page
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};
export default Programs;
