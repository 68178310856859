import React, { useEffect, useState } from "react";
import HeroSection from "./components/HeroSection";
import Cards from "../about/components/Cards";
import Container from "../../../components/Container";
import SpacerMini from "../../../components/SpacerMini";
import About from "../programs/components/About";
import UpdateAbout from "../updates/components/About";
import ProgramCards from "../programs/components/Cards";
import Spacer from "../../../components/Spacer";
import UpdateCardsSol from "../updates/components/Cards";
import UpdateCards from "./components/updateCards/Cards";
import { Link } from "react-router-dom";
import MultiCarousal from "../../../components/multicarousals/multi.carousal";
import axiosInstance from "../../../../utils/axios";
import SimpleSlider from "../../../components/slider/slider";
import Popup2 from "../../../components/popup2/popup2";

// import Features from "./components/Features";
// import Stats from "./components/Stats";
// import Testimonials from "./components/Testimonials";
// import CallToAction from "./components/CallToAction";
// import Blog from "./components/Blog";

const Home: React.FC = () => {
  const [programData, setProgramData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [open, setOpen] = useState(false);
  const [clickedData, setClickedData] = useState("");
  const [popupData, setPopupData] = useState({});
  const fetchUpdateData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/insights/list`
      );
      setUpdateData(response.data);
    } catch (err: any) {}
  };

  useEffect(() => {
    fetchUpdateData();
  }, []);

  const fetchProgramData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/events/list`
      );
      // console.log(response)
      setProgramData(response?.data?.results);
    } catch (err: any) {}
  };
  const fetchProgramDetails = async (id: any) => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/insights/${id}`
      );
      setPopupData(response.data);
    } catch (err: any) {}
  };
  useEffect(() => {
    fetchProgramDetails(clickedData);
  }, [clickedData]);
  useEffect(() => {
    fetchProgramData();
  }, []);
  return (
    <>
      <div className="flex items-center justify-center flex-col">
        <div className="">
          <HeroSection />
        </div>
        <Container>
          <Cards />
          <Spacer />
          {programData.length !== 0 && (
            <>
              <About />
              <Spacer />
              <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center h-full">
                {programData?.map(
                  (item, i) => i <= 2 && <ProgramCards key={i} data={item} />
                )}
              </div>
              {/* <div className="grid  grid-cols-1  text-center ">
          <MultiCarousal>
            {[1, 2, 3,4,5].map((item, i) => (
              <ProgramCards key={i} />
            ))}
          </MultiCarousal>s
        </div> */}
              <Spacer />
              <div className="flex justify-center">
                <Link
                  to={"/programs"}
                  // onClick={() => handleNav()}
                  className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-xl before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                >
                  <span className="relative text-sm font-semibold text-white">
                    View More
                  </span>
                </Link>
              </div>{" "}
            </>
          )}
          {updateData.length !== 0 && (
            <>
              <Spacer />
              <UpdateAbout />
              <Spacer />
              {/* <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center ">
          {[1, 2, 3].map((item, i) => (
            <UpdateCardsSol key={i} />
          ))}
        </div> */}
              <div className="max-w-[calc(100vw-50px)]">
                <SimpleSlider>
                  {updateData?.map((item, i) => (
                    <div className="px-5">
                      <UpdateCards
                        key={i}
                        data={item}
                        open={open}
                        setOpen={setOpen}
                        setClickedData={setClickedData}
                      />
                    </div>
                  ))}
                </SimpleSlider>
              </div>

              {/* <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center "> */}

              {/* </div> */}
              <Spacer />
              <div className="flex justify-center">
                <Link
                  to={"/updates"}
                  // onClick={() => handleNav()}
                  className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-xl before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                >
                  <span className="relative text-sm font-semibold text-white">
                    View More
                  </span>
                </Link>
              </div>
            </>
          )}
          <SpacerMini />
        </Container>
        {/* <Features />
      <Stats />
      <Testimonials />
      <CallToAction />
      <Blog/> */}

        {/* <About /> */}
      </div>
      {open && popupData && <Popup2 setOpen={setOpen} data={popupData} />}
    </>
  );
};

export default Home;
