import React from "react";

const About: React.FC = () => {
  return (
    <div
      className=" text-center flex justify-center "
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
    >
      <div className="container">
        <div className="welcome-content text-center ">
          <h5 className="font-medium text-xl text-gray-600 dark:text-gray-300">
            Who Are We
          </h5>
          <h1 className="font-medium text-3xl text-gray-900 dark:text-white p-3 ">
            Welcome to Strategic Sourcing Middle East
          </h1>
          <div className="w-full pt-3 flex  justify-center">
            <div className="border-b-2  border-b-black w-[100px] dark:border-b-white "></div>
          </div>
          <p
            data-aos="zoom-in"
            data-aos-duration="1000"
            className="p-10   text-gray-600 dark:text-gray-300 font-normal text-base"
          >
            Strategic Sourcing Middle East is a premier procurement consultancy
            based in the UAE, dedicated to delivering transformative solutions
            for businesses of all sizes. With a robust international presence
            and a network of over 10,000 vetted vendors, we are committed to
            enhancing procurement processes, driving efficiency, and creating
            strategic advantages for our clients. Our team of industry experts
            is passionate about leveraging innovative sourcing strategies to
            help organizations succeed in today's competitive landscape.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
