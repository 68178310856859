import axios, { AxiosInstance } from "axios";

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_API as string,
    timeout: 5000,

    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
    },
});

// Add a request interceptor to modify headers dynamically
axiosInstance.interceptors.request.use((config) => {
    if (config.data instanceof FormData) {
        config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default axiosInstance;

// axiosInstance.post("api/khdciugdf/ygiweqguywegd")