import React, { useEffect } from "react";
import AOS from "aos";
import { AuthLayout, ChildLayout, PrivateLayout, PublicLayout, Wrapper } from "./layout";
import { Route, RouteObject, Routes } from "react-router-dom";
import { PUBLIC_ROUTES, PRIVATE_ROUTES, AUTH_ROUTES } from "./routes";
import { RouteObjectWithSlug } from "./routes/authRoutes";
import PageNotFound from "./app/pages/common/PageNotFound"

export function isRouteObjectWithSlug(route: RouteObject): route is RouteObjectWithSlug {
  return 'slug' in route && 'path' in route;
}
const renderRoutes = (routes: RouteObjectWithSlug[], LayoutComponent: React.FC<any>, keyPrefix: string) =>
  routes.map((route, index) => (
    <Route
      path={route.path}
      element={<LayoutComponent slug={route.slug}>{route.element}</LayoutComponent>}
      key={`${keyPrefix}-root-${index}-${route.slug}`}
    >
      {route.children?.map((childRoute:RouteObjectWithSlug, childIndex:number) =>
        isRouteObjectWithSlug(childRoute) && (
          (
            <Route
              path={childRoute.path}
              element={<ChildLayout slug={childRoute.slug}>{childRoute.element}</ChildLayout>}
              key={`${keyPrefix}-child-${index}-${childIndex}-${childRoute.slug}`}
            />)
        ))}
    </Route>
  ));

const App: React.FC = () => {
  useEffect(() => {
    AOS.init({
        // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
  
    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 240, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 600, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  
  });
    AOS.refresh();
  }, []);
  return (
    <Wrapper>
      <Routes>
        {renderRoutes(PUBLIC_ROUTES, PublicLayout, "public")}
        {renderRoutes(PRIVATE_ROUTES, PrivateLayout, "private")}
        {renderRoutes(AUTH_ROUTES, AuthLayout, "auth")}
        <Route path="*" element={<PublicLayout slug="NOT_FOUND"><PageNotFound/></PublicLayout>} />
      </Routes>
    </Wrapper>
  );
};

export default App;
