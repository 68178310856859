import React, { useState } from "react";
import "./index.css";
// import Eye from "../../../../../assets/svg/eye";
import { Link, useNavigate } from "react-router-dom";
import Popup2 from "../../../../components/popup2/popup2";
import parse from "html-react-parser";

interface UpdateCardsProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  setClickedData: (data: any) => void; // Adjust `any` to the correct type of your data
  data: any; // Assuming `data` is a number. Change as per your use case.
}

const UpdateCards: React.FC<UpdateCardsProps> = ({
  open,
  setOpen,
  setClickedData,
  data,
}) => {
  const navigate = useNavigate();
  const handleNav = (id: string) => {
    navigate(`/updates/${id}`);
  };
  // const [showModalOrder, setShowModalOrder] = useState(false);
  return (
    <>
      <div className="flex flex-col items-center w-full max-w-full bg-gray-600 dark:bg-white/70 rounded-3xl ">
        <div
          className="bg-white border border-gray-300 dark:shadow-none dark:border-gray-700 dark:bg-gray-800 shadow-gray-600/10 rounded-3xl w-full"
          //   data-aos="zoom-in-up"
        >
          <img src={data?.image} className="update-card-image rounded-t-3xl" />
        </div>
        <div
          className="hover:shadow-glow group  rounded-3xl bg-white border border-gray-100 dark:shadow-none  shadow-2xl shadow-gray-600/10 update-card-content "
          //   data-aos="zoom-in-up"
        >
          <div
            className="bg-primary text-white rounded-t-3xl  "
            style={{ padding: "7px" }}
          >
            <div className="capitalize">{data?.name}</div>
          </div>
          <div className=" m-2 text-left">
            {/* <p className="my-2 text-gray-700  w-full max-w-md h-24 overflow-hidden text-ellipsis whitespace-normal leading-6"> */}
            <p className="my-2 text-gray-700  w-full max-w-md h-24 line-clamp-4">
              {data?.description
                ? parse(data?.description)
                : "No data to display"}
            </p>
            {/* <p className="text-primary readMore">...Read More</p> */}
          </div>
          {/* <div className="updateEye bg-primary hover:bg-white rounded-full hover:border hover:border-primary text-white hover:text-primary">
          

          <Eye />
        </div> */}
          <div className="w-[110%]  readMore flex justify-end">
            <div className="">
              <div
                // to={"/"}
                onClick={() => handleNav(data?.id)}
                className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
              >
                <span className="relative text-sm font-semibold text-white">
                  View More
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {showModalOrder && (
        <>
          <Popup2 />
        </>
      )} */}
    </>
  );
};

export default UpdateCards;
