import React, { useEffect, useState } from "react";
import About from "./components/About";
import Cards from "./components/Cards";
import Container from "../../../components/Container";
import Whatdo from "./components/Whatdo";
import Team from "./components/Team";
import Spacer from "../../../components/Spacer";
import { useNavigate, useParams } from "react-router-dom";
import DetailData from "./components/detail";
import SpacerMini from "../../../components/SpacerMini";
import axiosInstance from "../../../../utils/axios";
import { FaChevronLeft } from "react-icons/fa";

const ProgramDetail: React.FC = () => {
  const param = useParams().id;
  const [data, setData] = useState<any>({});
  const fetchProgrameData = async (id: any) => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/events/${id}`
      );
      setData(response.data);
    } catch (err: any) {}
  };

  useEffect(() => {
    fetchProgrameData(param);
  }, []);
  const navigate = useNavigate();
  return (
    <div className="relative" id="home">
      <div
        aria-hidden="true"
        className="absolute  grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
      >
        <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
        <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600"></div>
      </div>{" "}
      <div className="flex items-center justify-center flex-col">
        <div className="py-32 w-full">
          <Container>
            {/* <About /> */}
            <div
              className="flex items-center text-primary cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <FaChevronLeft />
              <span className="pl-2 pb-[1px]">Back</span>{" "}
            </div>
            <SpacerMini />
            <Cards data={data} />
            <Spacer />

            <DetailData data={data} />
            <Spacer />
            <div className="" data-aos="zoom-in-up">
              {data?.gform_link && (
                <div className=" z-50">
                  <iframe
                    src={data?.gform_link}
                    title="program registration"
                    style={{ width: "100%", height: "calc(100vh)" }}
                    className="block"
                  ></iframe>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};
export default ProgramDetail;
