import React from "react";
import parse from "html-react-parser";

interface ProgProps {
  data: any;
}
const Filter: React.FC<ProgProps> = ({ data }) => {
  return (
    <>
      {/* <div
        className={` z-50 group p-2 sm:p-2 rounded-xl bg-white border border-gray-100 dark:border-gray-700   shadow-gray-600/10 
      
       `}
      >
        <div className=" grid gap-4 md:grid-cols-3 lg:grid-cols-3 grid-cols-1 ">
          <div className="flex md:justify-center justify-start">
            <span className="text-primary font-bold text-[14px]">From :</span>
            <span className="font-bold text-[14px] pl-3">12-01-25, 09:00</span>
          </div>
          <div className="flex md:justify-center justify-start">
            <span className="text-primary font-bold text-[14px]">To:</span>
            <span className="font-bold text-[14px] pl-3">12-01-25, 09:00</span>
          </div>
          <div className="flex md:justify-center justify-start">
            <span className="text-primary font-bold text-[14px]">Venue:</span>
            <span className="font-bold text-[14px] pl-3">Online</span>
          </div>
        </div>
      </div> */}
      <div
        className="p-6 sm:p-8 rounded-3xl bg-white border border-gray-100 dark:shadow-none dark:border-gray-700 dark:bg-gray-800 text-gray-600 dark:text-gray-300  "
        data-aos="zoom-in-up"
      >
        <div className="mt-6 relative">
          <h3 className="text-2xl font-semibold text-gray-800 dark:text-white">
            Description
          </h3>
          <p className="mt-6 mb-8 rtf">
            {data?.description
              ? parse(data?.description)
              : "No data to display"}
            {/* Our mission is to empower organizations through exceptional
            procurement consultancy services. We focus on optimizing supply
            chains, reducing costs, and enabling our clients to achieve their
            strategic objectives. By delivering innovative sourcing solutions
            tailored to individual needs, we position ourselves as trusted
            partners in our clients' pursuit of operational excellence and
            sustainable growth. Our mission is to empower organizations through
            exceptional procurement consultancy services. We focus on optimizing
            supply chains, reducing costs, and enabling our clients to achieve
            their strategic objectives. By delivering innovative sourcing
            solutions tailored to individual needs, we position ourselves as
            trusted partners in our clients' pursuit of operational excellence
            and sustainable growth. Our mission is to empower organizations
            through exceptional procurement consultancy services. We focus on
            optimizing supply chains, reducing costs, and enabling our clients
            to achieve their strategic objectives. By delivering innovative
            sourcing solutions tailored to individual needs, we position
            ourselves as trusted partners in our clients' pursuit of operational
            excellence and sustainable growth. */}
          </p>
        </div>
      </div>
    </>
  );
};

export default Filter;
