// routes.ts
import { RouteObject } from "react-router-dom"; // Import type for Routes if needed
import { routePaths } from "./paths";


export type RouteObjectWithSlug = (RouteObject & { slug: string } & { children?: RouteObjectWithSlug[] })
export const routes: RouteObjectWithSlug[] = [

  {
    path: routePaths.LOGIN,
    element: <>Login</>,
    slug: "LOGIN"
  },
  {
    path: routePaths.REGISTER,
    element: <>Register</>,
    slug: "REGISTER"
  },

];
