import { create } from "zustand";
import { AuthStoreType } from "./types";



// Create the auth store
const useAuthStore = create<AuthStoreType>((set, get) => ({
  userData: null,
  loading: false,

  user: () => ({
    user_id: get().userData?.user_id || null,
    username: get().userData?.username || null,
  }),

  setUser: (user) => set({ userData: user }),
  setLoading: (loading) => set({ loading }),

  isLoggedIn: () => get().userData !== null,
}));



export { useAuthStore };
