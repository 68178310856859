import { SeoData } from "../seo/SeoContext";

export const routePaths = {
  HOME: "/",
  ABOUT: "/about",
  CAREERS: "/careers",
  LOGIN: "/login",
  CONTACT_US: "/contact",
  REGISTER: "/register",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  PRIVACY_POLICY: "/privacy-policy",
  PROGRAMS: "/programs",
  PROGRAM_DETAILS: `/programs/:id`,
  UPDATES: "/updates",
  UPDATE_DETAILS: "/updates/:id",
  CAREER_DETAILS: "/careers/:id",
};

export const routeSEO: { [key: string]: SeoData } = {
  HOME: {
    title: "Strategic Sourcing - HOME",
    description: "",
    keywords: "",
  },
  ABOUT: {
    title: "Strategic Sourcing - ABOUT",
    description: "",
    keywords: "",
  },
  CAREERS: {
    title: "Strategic Sourcing - CAREERS",
    description: "",
    keywords: "",
  },
  CONTACT_US: {
    title: "Strategic Sourcing - CONTACT",
    description: "",
    keywords: "",
  },
  LOGIN: {
    title: "Strategic Sourcing - LOGIN",
    description: "",
    keywords: "",
  },
  REGISTER: {
    title: "Strategic Sourcing - REGISTER",
    description: "",
    keywords: "",
  },
  TERMS_AND_CONDITIONS: {
    title: "Strategic Sourcing - Terms and Conditions",
    description: "",
    keywords: "",
  },
  PRIVACY_POLICY: {
    title: "Strategic Sourcing - Privacy Policy",
    description: "",
    keywords: "",
  },
  PROGRAMS: {
    title: "Strategic Sourcing - Programs",
    description: "",
    keywords: "",
  },
  PROGRAM_DETAILS: {
    title: "Strategic Sourcing - Program Details",
    description: "",
    keywords: "",
  },
  UPDATES: {
    title: "Strategic Sourcing - Updates",
    description: "",
    keywords: "",
  },
  UPDATE_DETAILS: {
    title: "Strategic Sourcing - Update Details",
    description: "",
    keywords: "",
  },
  CAREER_DETAILS: {
    title: "Strategic Sourcing - Career Details",
    description: "",
    keywords: "",
  },
};
