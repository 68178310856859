import { Navigate } from "react-router-dom";

import React, { useEffect } from "react";


import { ROUTE_PATHS } from "../routes";
import { useAuthStore } from "../utils/auth/store";
import { useSeo } from "../seo/SeoContext";
import { routeSEO } from "../routes/paths";
import { SEO_DEFAULTS } from "../seo/constants";

interface AuthRouteProps {
  children: React.ReactNode;
  slug: string
}

export const Auth: React.FC<AuthRouteProps> = ({ children, slug }) => {
  // Directly access isLoggedIn from the state
  const { updateSeoData } = useSeo();
  useEffect(() => {

    updateSeoData(routeSEO[slug]?.title || SEO_DEFAULTS.TITLE, routeSEO[slug].description || SEO_DEFAULTS.DESC, routeSEO[slug]?.keywords || SEO_DEFAULTS.KEYWORDS)
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [slug ]);
  const loggedIn = useAuthStore((state) => state.isLoggedIn());

  // Render children if logged in, otherwise redirect to login page
  return loggedIn ? <Navigate to={ROUTE_PATHS.HOME} /> : <>{children}</>;
};

