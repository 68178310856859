import React, { useEffect } from "react";
import { useSeo } from "../seo/SeoContext";
import { SEO_DEFAULTS } from "../seo/constants";
import { routeSEO } from "../routes/paths";
interface ChildRouteProps {
  children: React.ReactNode;
  slug: string
}


export const Child: React.FC<ChildRouteProps> = ({ children, slug }) => {

  const { updateSeoData } = useSeo();

  useEffect(() => {

    updateSeoData(routeSEO[slug]?.title || SEO_DEFAULTS.TITLE, routeSEO[slug].description || SEO_DEFAULTS.DESC, routeSEO[slug]?.keywords || SEO_DEFAULTS.KEYWORDS)
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug ]);


  // Render children if logged in, otherwise redirect to login page
  return <>{children}</>;
};

