// routes.ts
import React from "react";
import { RouteObject } from "react-router-dom"; // Import type for Routes if needed

import AboutPage from "../app/pages/public/about";
import { routePaths } from "./paths";
import Home from "../app/pages/public/home";
// import Careers from "../app/pages/public/contact";
import Contact from "../app/pages/public/contact";
import PrivacyPolicy from "../app/pages/public/privacy";
import TermsAndConditions from "../app/pages/public/terms";
import Programs from "../app/pages/public/programs";
import Updates from "../app/pages/public/updates";
import ProgramDetail from "../app/pages/public/programDetail";
import UpdateDetails from "../app/pages/public/updateDetail";
import CareerDetail from "../app/pages/public/carrerDetail";
import Career from "../app/pages/public/careers";

export type RouteObjectWithSlug = RouteObject & {
  slug: string;
  children?: RouteObjectWithSlug[]; // Nested routes with slug if needed
};
export const routes: RouteObjectWithSlug[] = [
  {
    path: routePaths.HOME,
    element: <Home />,
    slug: "HOME",
  },
  {
    path: routePaths.ABOUT,
    element: <AboutPage />,
    slug: "ABOUT",
  },
  {
    path: routePaths.CAREERS,
    element: <Career />,
    slug: "CAREERS",
  },
  {
    path: routePaths.CONTACT_US,
    element: <Contact />,
    slug: "CONTACT_US",
  },
  {
    path: routePaths.PRIVACY_POLICY,
    element: <PrivacyPolicy />,
    slug: "PRIVACY_POLICY",
  },
  {
    path: routePaths.TERMS_AND_CONDITIONS,
    element: <TermsAndConditions />,
    slug: "TERMS_AND_CONDITIONS",
  },
  {
    path: routePaths.PROGRAMS,
    element: <Programs />,
    slug: "PROGRAMS",
  },
  {
    path: routePaths.PROGRAM_DETAILS,
    element: <ProgramDetail />,
    slug: "PROGRAM_DETAILS",
  },
  {
    path: routePaths.UPDATES,
    element: <Updates />,
    slug: "UPDATES",
  },
  {
    path: routePaths.UPDATE_DETAILS,
    element: <UpdateDetails />,
    slug: "UPDATE_DETAILS",
  },

  {
    path: routePaths.CAREER_DETAILS,
    element: <CareerDetail />,
    slug: "CAREER_DETAILS",
  },
];
